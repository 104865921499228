// React Router
import { Routes, Route, Navigate } from "react-router-dom";
// Game Pages
import Games from './pages/games/all';
import Minecraft from './pages/games/minecraft';
import Rust from './pages/games/rust';
import Ark from './pages/games/ark';
import GarrysMod from './pages/games/garrysMod';
import Hytale from './pages/games/hytale';
import Arma from './pages/games/arma';
import Csgo from './pages/games/csgo';
import Valheim from './pages/games/valheim';
import fxserver from './pages/games/fxserver';
// Hosting Pages
import Web from './pages/hosting/web';
import Discord from './pages/hosting/discord';
import Reseller from './pages/hosting/reseller';
// Misc Pages
import Home from './pages/home';
import Affiliates from './pages/information/affiliates';
import About from './pages/information/about';
import Partners from './pages/information/partners';
import Modpack from './pages/information/modpack';
import Error from './pages/404';
// Legal Pages
import Terms from './pages/legal/terms';
import Privacy from './pages/legal/privacy';
// Scroll Top
import ScrollTop from './components/scrollTop';

function App() {
  return (
    <div>
      <ScrollTop />
      <Routes>
        {/* Home */}
        <Route path="/" element={<Home />} />
        {/* Games */}
        <Route path="/games" element={<Games />} />
        <Route path="/games/minecraft" element={<Minecraft />} />
        {/*<Route path="/games/rust" element={<Rust />} />*/}
        <Route path="/games/ark" element={<Ark />} />
        <Route path="/games/garrys-mod" element={<GarrysMod />} />
        {/*<Route path="/games/hytale" element={<Hytale />} />*/}
        {/*<Route path="/games/arma" element={<Arma />} />*/}
        {/*<Route path="/games/csgo" element={<Csgo />} />*/}
        <Route path="/games/valheim" element={<Valheim />} />
        {/* Hosting */}
        <Route path="/hosting/web-hosting" element={<Web />} />
        <Route path="/hosting/discord" element={<Discord />} />
        <Route path="/hosting/reseller" element={<Reseller />} />
        {/* Information */}
        <Route path="/affiliates" element={<Affiliates />} />
        <Route path="/about" element={<About />} />
        <Route path="/partners" element={<Partners />} />
        <Route path="/modpacks" element={<Modpack />} />
        {/*<Route path="/howie" element={<Howie />} />*/}
        {/* Legal */}
        <Route path="/legal/terms" element={<Terms />} />
        <Route path="/legal/privacy" element={<Privacy />} />
        
        {/* 404 */}
        <Route path="*" element={<Error />} status={404} />
        {/* Route Redirects */}
        <Route path="/minecraft" element={<Navigate to="/games/minecraft" />} />
        <Route path="/ark" element={<Navigate to="/games/ark" />} />
        <Route path="/valheim" element={<Navigate to="/games/valheim" />} />
        <Route path="/website-hosting" element={<Navigate to="/hosting/web-hosting" />} />
        <Route path="/reseller-hosting" element={<Navigate to="/hosting/reseller" />} />
        <Route path="/discord-bots" element={<Navigate to="/hosting/discord" />} />
        <Route path="/terms-of-service" element={<Navigate to="/legal/terms" />} />
        <Route path="/privacy-policy" element={<Navigate to="/legal/privacy" />} />
        <Route path="/about-us" element={<Navigate to="/about" />} />
      </Routes>
    </div>
  );
}

export default App;