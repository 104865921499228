// Components
import SimpleHero from '../../components/heros/simple';
import Footer from '../../components/footer';
// React useState
import { useState } from 'react';
// Scroll on Click
import { Link, Element, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll';

const Privacy = () => {
    const [activeSlide, setSlide] = useState(1);

    return (
        <main>
            <SimpleHero title="Privacy Policy" />
            <section className='privacy py-28 max-w-7xl mx-auto px-5 sm:px-5 lg:px-5'>
                <div className='primary-gradient px-6 sm:px-6 md:px-6 lg:px-12 rounded-xl pt-16 pb-28'>
                    <div className='grid grid-cols-1 sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-5'>
                        <div className='navigation hidden sm:hidden md:block lg:block col-span-1'>
                            <ul>
                                <li className={activeSlide === 1 ? 'text-white font-bold legal-item cursor-pointer text-2xl mb-8' : 'text-gray-300 font-semibold legal-item cursor-pointer text-2xl mb-8'}>
                                    <Link to="introduction" spy={true} smooth={true} offset={50} duration={1000} onClick={() => setSlide(1)}>
                                        Introduction
                                    </Link>
                                </li>
                                <li className={activeSlide === 2 ? 'text-white font-bold legal-item cursor-pointer text-2xl mb-8' : 'text-gray-300 font-semibold legal-item cursor-pointer text-2xl mb-8'}>
                                    <Link to="personal" spy={true} smooth={true} offset={50} duration={1000} onClick={() => setSlide(2)}>
                                        Personal Information
                                    </Link>
                                </li>
                                <li className={activeSlide === 3 ? 'text-white font-bold legal-item cursor-pointer text-2xl mb-8' : 'text-gray-300 font-semibold legal-item cursor-pointer text-2xl mb-8'}>
                                    <Link to="data" spy={true} smooth={true} offset={50} duration={1000} onClick={() => setSlide(3)}>
                                        Your Data
                                    </Link>
                                </li>
                                <li className={activeSlide === 4 ? 'text-white font-bold legal-item cursor-pointer text-2xl mb-8' : 'text-gray-300 font-semibold legal-item cursor-pointer text-2xl mb-8'}>
                                    <Link to="logging" spy={true} smooth={true} offset={50} duration={1000} onClick={() => setSlide(4)}>
                                        Logging Data
                                    </Link>
                                </li>
                                <li className={activeSlide === 5 ? 'text-white font-bold legal-item cursor-pointer text-2xl mb-8' : 'text-gray-300 font-semibold legal-item cursor-pointer text-2xl mb-8'}>
                                    <Link to="cookies" spy={true} smooth={true} offset={50} duration={1000} onClick={() => setSlide(5)}>
                                        Use of Cookies
                                    </Link>
                                </li>
                                <li className={activeSlide === 6 ? 'text-white font-bold legal-item cursor-pointer text-2xl mb-8' : 'text-gray-300 font-semibold legal-item cursor-pointer text-2xl mb-8'}>
                                    <Link to="security" spy={true} smooth={true} offset={50} duration={1000} onClick={() => setSlide(6)}>
                                        Security
                                    </Link>
                                </li>
                                <li className={activeSlide === 7 ? 'text-white font-bold legal-item cursor-pointer text-2xl mb-8' : 'text-gray-300 font-semibold legal-item cursor-pointer text-2xl mb-8'}>
                                    <Link to="links" spy={true} smooth={true} offset={50} duration={1000} onClick={() => setSlide(7)}>
                                        Links to other Websites
                                    </Link>
                                </li>
                                <li className={activeSlide === 8 ? 'text-white font-bold legal-item cursor-pointer text-2xl mb-8' : 'text-gray-300 font-semibold legal-item cursor-pointer text-2xl mb-8'}>
                                    <Link to="changes" spy={true} smooth={true} offset={50} duration={1000} onClick={() => setSlide(8)}>
                                        Changes to this Statment
                                    </Link>
                                </li>
                                <li className={activeSlide === 9 ? 'text-white font-bold legal-item cursor-pointer text-2xl mb-8' : 'text-gray-300 font-semibold legal-item cursor-pointer text-2xl mb-8'}>
                                    <Link to="contact" spy={true} smooth={true} offset={50} duration={1000} onClick={() => setSlide(9)}>
                                        Contact Information
                                    </Link>
                                </li>
                            </ul>
                        </div>
                        <div className='terms col-span-1 sm:col-span-1 md:col-span-2 lg:col-span-2'>
                            <Element name="introduction">
                                <div className='introduction mb-12'>
                                    <h1 className='text-gray-300 uppercase font-semibold text-lg mb-2.5'>Introduction</h1>
                                    <p className='text-gray-300'>We, Cyperhost, know that protecting your private information is our priority. This Statement of Privacy applies to the Cyperhost.com and Cyperhost and governs data collection and usage. For the purposes of this Privacy Policy, unless otherwise noted, all references to Cyperhost include Cyperhost.com. By using the Cyperhost website, you consent to the data practices described in this statement.</p>
                                </div>
                            </Element>
                            <Element name="personal">
                                <div className='personal mb-12'>
                                    <h1 className='text-gray-300 uppercase font-semibold text-lg mb-2.5'>PERSONAL INFORMATION</h1>
                                    <p className='text-gray-300'>While using our Service, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you. Personally, identifiable information may include but is not limited to, your name, email, phone number, postal address ("Personal Information"). Any personal information you provide on Cyperhost.com will not be given to any outside parties for any purpose. Information that you provide is strictly for use on Cyperhost.com.</p>
                                </div>
                            </Element>
                            <Element name="data">
                                <div className='data mb-12'>
                                    <h1 className='text-gray-300 uppercase font-semibold text-lg mb-2.5'>Your Data</h1>
                                    <p className='text-gray-300'>The data that we collect including Server Files, Client Profile Infromation is located in Quebec, Canada. Our Backup locations are Quebec, Canada.</p>
                                </div>
                            </Element>
                            <Element name="logging">
                                <div className='logging mb-12'>
                                    <h1 className='text-gray-300 uppercase font-semibold text-lg mb-2.5'>Logging Data</h1>
                                    <p className='text-gray-300'>We collect information that your browser sends whenever you visit our Service ("Log Data"). This Log Data may include information such as your computer's Internet Protocol ("IP") address, browser type, browser version, the pages of our Service that you visit, the time and date of your visit, the time spent on those pages and other statistics.</p>
                                </div>
                            </Element>
                            <Element name="cookies">
                                <div className='cookies mb-12'>
                                    <h1 className='text-gray-300 uppercase font-semibold text-lg mb-2.5'>Use of Cookies</h1>
                                    <p className='text-gray-300'>Cookies are files with small amount of data, which may include an anonymous unique identifier. Cookies are sent to your browser from a website and stored on your computer's hard drive. We use "cookies" to collect information. You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of our Service.</p>
                                </div>
                            </Element>
                            <Element name="security">
                                <div className='security mb-12'>
                                    <h1 className='text-gray-300 uppercase font-semibold text-lg mb-2.5'>Security</h1>
                                    <p className='text-gray-300'>The security of your Personal information is important to us. We implement a variety of security measures to maintain the safety of your personal information. Some user data is stored in your browser cookies, however under no circumstance is any sensitive data is stored in your browser cookies. All user-provided data is stored in our databases (username, email, address, etc). Any sensitive information (such as passwords) is encrypted or hashed. We store your account password using a strong hash function. This would make it very expensive (and virtually impossible) for an attacker to recover your password if they gained access to the hashes. Private information such as credit card numbers are not stored in our database and never will be, we only store the Card Type, Last 4 Digits of your Credit Card Number, Expiry Date and the Remote Token. The full card number never touches our servers.</p>
                                </div>
                            </Element>
                            <Element name="links">
                                <div className='links mb-12'>
                                    <h1 className='text-gray-300 uppercase font-semibold text-lg mb-2.5'>Links to other Websites</h1>
                                    <p className='text-gray-300'>Our Service may contain links to other sites that are not operated by us. If you click on a third party link, you will be directed to that third party's site. We strongly advise you to review the Privacy Policy of every site you visit. We have no control over and assume no responsibility for the content, privacy policies or practices of any third party sites or services.</p>
                                </div>
                            </Element>
                            <Element name="changes">
                                <div className='changes mb-12'>
                                    <h1 className='text-gray-300 uppercase font-semibold text-lg mb-2.5'>Changes to this Statement</h1>
                                    <p className='text-gray-300'>We may update our Privacy Policy from time to time without notice. You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.</p>
                                </div>
                            </Element>
                            <Element name="contact">
                                <div className='contact mb-12'>
                                    <h1 className='text-gray-300 uppercase font-semibold text-lg mb-2.5'>Contact Information</h1>
                                    <p className='text-gray-300'>Cyperhost welcomes your questions or comments regarding this Statement of Privacy. If you believe that Cyperhost has not adhered to this Statement, please contact Cyperhost at: legal@cyperhost.com.</p>
                                </div>
                            </Element>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </main>
    )
}

export default Privacy