// Images
import Truspilot from '../../assets/images/trustpilot.svg';

const Card = ({ data }) => {
    return (
        <div className="review-card">
            <div className="bg-gray-200 py-8 px-8 rounded-lg shadow-xl">
                <img src={Truspilot} alt="Trustpilot" className='mb-6 w-48' />
                <h1 className="text-xl font-semibold mb-5 select-none">{data.title}</h1>
                <p className="text-sm mb-4 select-none">{data.description}</p>
                <a href={data.link} className="text-green-400 select-none">{data.client}</a>
            </div>
        </div>
    )
}

export default Card