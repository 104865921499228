// Components
import ReviewCard from './reviewCard';
// Tiny Slider
import TinySlider from "tiny-slider-react";
// Images
import Truspilot from '../../assets/images/trustpilot.svg';

const Reviews = () => {
    const sliderSettings = {
        container: '#center',
        center: true,
        loop: false,
        swipeAngle: false,
        speed: 100,
        nav: false,
        lazyload: true,
        mouseDrag: true,
        controls: false,
        gutter: 30,
        responsive: {
            500: {
              items: 1
            },
            900: {
                items: 3
            },
            1200: {
              items: 4
            },
            1500: {
                items: 4
            }
        }
    }

    const reviews = [
        { id: 1, stars: 5, client: 'Lauren', link: 'https://www.trustpilot.com/reviews/61d1d3a20430e828ebe57f0f', title: '$1/Month Bot hosting (10/10)', description: 'Management of Cyperhost is extremely nice. I’ve talked to two people on the high side of their staff team and I’ve had no issues with anyone. The host itself runs great for what I need.' },
        { id: 1, stars: 5, client: 'Bud Wedge', link: 'https://www.trustpilot.com/reviews/5fb008f75e693f0a84271689', title: 'Highly recommended.', description: 'The staff and the owners are absolutely amazing. The prices of the products are very cheap but very very good for the price. With no downtime and no issues with server hosting I would highly recommend.' },
        { id: 1, stars: 5, client: 'Grant McClendon', link: 'https://www.trustpilot.com/reviews/5f4f0c1602e8570814fdf033', title: 'Great Hosting Company', description: 'This is the one of the best hosting companies that I have ever dealt with. The services are amazing with great prices and the up time of the services is outstanding!' },
        { id: 1, stars: 5, client: 'John Roderick', link: 'https://www.trustpilot.com/reviews/5f3884239cc22a0600cca822', title: 'Good Support', description: 'The support team is very great and i would love to buy a server from them if i wanted to they are the best hosting service ive been working with. KEEP UP THE GOOD WROK!!! :)' },
        { id: 1, stars: 5, client: 'Fusion', link: 'https://www.trustpilot.com/reviews/5ee42a437dd7530708823b55', title: 'Wonderful Hosting!', description: 'I personally find this hosting service amazing. Everything within this company is made from the ground up and not stolen like some companies like to do. I will and keep promoting this host!' }
    ]

    return (
        <div className="reviews max-w-7xl sm:max-w-7xl lg:max-w-none mx-auto sm:mx-auto lg:mx-0 px-5 sm:px-5 lg:px-0 py-28">
            <TinySlider settings={sliderSettings}>
                <div className="header">
                    <p className="text-gray-100 font-semibold text-4xl leading-snug mb-16">Check what Users say about us.</p>
                    <img src={Truspilot} alt="Trustpilot" className='mb-7' />
                    <a href="https://www.trustpilot.com/review/cyperhost.com" target="_blank" className="text-gray-300 font-semibold">4.6 / 5.0 (37 Reviews)</a>
                </div>
                {reviews.map(review => <ReviewCard data={review} key={review.id} />)}
            </TinySlider>
        </div>
    )
}

export default Reviews