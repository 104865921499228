// Components
import SimpleHero from '../../components/heros/simple';
import Footer from '../../components/footer';
// React useState
import { useState } from 'react';
// Scroll on Click
import { Link, Element, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll';

const Terms = () => {
    const [activeSlide, setSlide] = useState(1);

    return (
        <main>
            <SimpleHero title="Terms of Service" />
            <section className='terms py-28 max-w-7xl mx-auto px-5 sm:px-5 lg:px-5'>
                <div className='primary-gradient px-6 sm:px-6 md:px-6 lg:px-12 rounded-xl pt-16 pb-28'>
                    <div className='grid grid-cols-1 sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-5'>
                        <div className='navigation hidden sm:hidden md:block lg:block col-span-1'>
                            <ul>
                                <li className={activeSlide === 1 ? 'text-white font-bold legal-item cursor-pointer text-2xl mb-8' : 'text-gray-300 font-semibold legal-item cursor-pointer text-2xl mb-8'}>
                                    <Link to="introduction" spy={true} smooth={true} offset={50} duration={1000} onClick={() => setSlide(1)}>
                                        Introduction
                                    </Link>
                                </li>
                                <li className={activeSlide === 2 ? 'text-white font-bold legal-item cursor-pointer text-2xl mb-8' : 'text-gray-300 font-semibold legal-item cursor-pointer text-2xl mb-8'}>
                                    <Link to="permission" spy={true} smooth={true} offset={50} duration={1000} onClick={() => setSlide(2)}>
                                        Permission to Host your Content
                                    </Link>
                                </li>
                                <li className={activeSlide === 3 ? 'text-white font-bold legal-item cursor-pointer text-2xl mb-8' : 'text-gray-300 font-semibold legal-item cursor-pointer text-2xl mb-8'}>
                                    <Link to="domain" spy={true} smooth={true} offset={50} duration={1000} onClick={() => setSlide(3)}>
                                        Domain Registration
                                    </Link>
                                </li>
                                <li className={activeSlide === 4 ? 'text-white font-bold legal-item cursor-pointer text-2xl mb-8' : 'text-gray-300 font-semibold legal-item cursor-pointer text-2xl mb-8'}>
                                    <Link to="transfers" spy={true} smooth={true} offset={50} duration={1000} onClick={() => setSlide(4)}>
                                        Transfers
                                    </Link>
                                </li>
                                <li className={activeSlide === 5 ? 'text-white font-bold legal-item cursor-pointer text-2xl mb-8' : 'text-gray-300 font-semibold legal-item cursor-pointer text-2xl mb-8'}>
                                    <Link to="use" spy={true} smooth={true} offset={50} duration={1000} onClick={() => setSlide(5)}>
                                        Acceptable Use
                                    </Link>
                                </li>
                                <li className={activeSlide === 6 ? 'text-white font-bold legal-item cursor-pointer text-2xl mb-8' : 'text-gray-300 font-semibold legal-item cursor-pointer text-2xl mb-8'}>
                                    <Link to="refunds" spy={true} smooth={true} offset={50} duration={1000} onClick={() => setSlide(6)}>
                                        Refunds and Disputes
                                    </Link>
                                </li>
                                <li className={activeSlide === 7 ? 'text-white font-bold legal-item cursor-pointer text-2xl mb-8' : 'text-gray-300 font-semibold legal-item cursor-pointer text-2xl mb-8'}>
                                    <Link to="termination" spy={true} smooth={true} offset={50} duration={1000} onClick={() => setSlide(7)}>
                                        Termination of Use
                                    </Link>
                                </li>
                                <li className={activeSlide === 8 ? 'text-white font-bold legal-item cursor-pointer text-2xl mb-8' : 'text-gray-300 font-semibold legal-item cursor-pointer text-2xl mb-8'}>
                                    <Link to="payment" spy={true} smooth={true} offset={50} duration={1000} onClick={() => setSlide(8)}>
                                        Payment Information
                                    </Link>
                                </li>
                                <li className={activeSlide === 9 ? 'text-white font-bold legal-item cursor-pointer text-2xl mb-8' : 'text-gray-300 font-semibold legal-item cursor-pointer text-2xl mb-8'}>
                                    <Link to="liability" spy={true} smooth={true} offset={50} duration={1000} onClick={() => setSlide(9)}>
                                        Liability Limitation and Exclusion
                                    </Link>
                                </li>
                            </ul>
                        </div>
                        <div className='terms col-span-1 sm:col-span-1 md:col-span-2 lg:col-span-2'>
                            <Element name="introduction">
                                <div className='introduction mb-12'>
                                    <h1 className='text-gray-300 uppercase font-semibold text-lg mb-2.5'>Introduction</h1>
                                    <p className='text-gray-300'>Please read these Terms of Service ("Agreement", "Terms of Service") carefully before using Cyperhost.com ("the Site") operated by Cyperhost ("us", "we", or "our"). This Agreement sets forth the legally binding terms and conditions for your use of the Site at Cyperhost.com By accessing or using the Site in any manner, including, but not limited to, visiting or browsing the Site or contributing content or other materials to the Site, you agree to be bound by these Terms of Service. Capitalized terms are defined in this Agreement. These Terms, and any rights and licenses granted hereunder, may not be transferred or assigned by you, but may be assigned by Cyperhost without restriction. Any attempted transfer or assignment in violation hereof shall be null and void. No warranties of any kind (including implied warranties of merchantability or fitness for a particular purpose) are given with respect to the proprietary information disclosed or used under this agreement, and neither party shall be liable to the other damages arising out of or caused by defects or deficiencies in the proprietary information of either party, whether direct, incidental, consequential or otherwise.</p>
                                </div>
                            </Element>
                            <Element name="permission">
                                <div className='permission mb-12'>
                                    <h1 className='text-gray-300 uppercase font-semibold text-lg mb-2.5'>Permission to Host your Content</h1>
                                    <p className='text-gray-300'>Upon receiving your payment for our services we will set up your account. In addition, you will need to provide us an email that is active that we will be able to reach you at all times typically an email address that is not @ the domain(s) you've signed up for, this will be our main form of contact in the event that you may have violated an abuse clause and/or any part of this agreement. If we feel that you have failed to meet this requirement, the order may be considered fraudulent in nature and could be potentially denied.</p>
                                </div>
                            </Element>
                            <Element name="domain">
                                <div className='domain mb-12'>
                                    <h1 className='text-gray-300 uppercase font-semibold text-lg mb-2.5'>Domain Registration</h1>
                                    <p className='text-gray-300'>When purchasing a domain with us you are giving explicit permission to give your contact and billing details to NameSilo. Please look over NameSilo's Terms of Service and Privacy Policy as we are not responsible for anything that happens on their end. Domains typically take up to 48 hours for it to be processed. Domains can be suspended with reason if they violate our terms of service or a United States, Swedish or Canadian law, and a refund will not be given.</p>
                                </div>
                            </Element>
                            <Element name="transfers">
                                <div className='transfers mb-12'>
                                    <h1 className='text-gray-300 uppercase font-semibold text-lg mb-2.5'>Transfers</h1>
                                    <p className='text-gray-300'>We do provide the transferring of any server as a courtesy service but we cannot make any guarantee that during the transfer process we will be able to move your server. Our service department will make an effort to move your server to our hosting platform but the amount of time can vary immensely depending on how the server was previously configured. We always strive to make this a seamless process and try to avoid any interruption to existing servers but in some cases we may be unable to assist you in a transfer of data from the old server. We are not responsible for data loss in transfers.</p>
                                </div>
                            </Element>
                            <Element name="use">
                                <div className='use mb-12'>
                                    <h1 className='text-gray-300 uppercase font-semibold text-lg mb-2.5'>Acceptable Use/Illegal Activity</h1>
                                    <p className='text-gray-300 mb-5'>Cyperhost strives to maintain a high-level of service, and a lot of customers depend on our high standards of quality. As such, we will not provide services to those that are using our services for:</p>
                                    <ol className='list-disc ml-5 mb-7'>
                                        <li className='text-gray-300 mb-1.5'>
                                            Hacking, which includes, for example penetrating or attempting to access, without authorization, another computer or network. Port scans, stealth scans, and fraudulent credit card "phishing" techniques are also prohibited.
                                        </li>
                                        <li className='text-gray-300 mb-1.5'>
                                            Hosting of files, that are under copyright (if not bought) or other data that infringes on another's copyright or other intellectual property rights.
                                        </li>
                                        <li className='text-gray-300 mb-1.5'>
                                            Spamming, or sending of bulk unsolicited email. We maintain a strict policy on spamming, which includes the sending of unauthorized commercial messages by use of our services, or by maintaining an open SMTP connection. We reserve the right to refuse or terminate service based on reasonable indications that you are engaged in spamming of any sort.
                                        </li>
                                        <li className='text-gray-300 mb-1.5'>
                                            Uploading or linking to any content that violates another's right of publicity or privacy.
                                        </li>
                                        <li className='text-gray-300 mb-1.5'>
                                            Distributing hate speech, or any other content that is obscene, abusing, this could be considered libelous and defamatory.
                                        </li>
                                        <li className='text-gray-300 mb-1.5'>
                                            Hosting, storing, or distributing child pornography. If any such content is found and brought to our attention, the proper law enforcement agencies will be notified.
                                        </li>
                                        <li className='text-gray-300 mb-1.5'>
                                            Child pornography, bestiality, hate speech, scam sites, phishing sites and any other material which is considered to be illegal in United States or Canada is strictly prohibited.
                                        </li>
                                        <li className='text-gray-300 mb-1.5'>
                                            Hosting, storing, or distributing pornographic material. This includes sites that may provide links to adult content elsewhere. In addition, our servers and services may not be used for the propagation, distribution, housing, processing, storing or otherwise handling in any way lewd, obscene, or satanic materials.
                                        </li>
                                        <li className='text-gray-300 mb-1.5'>
                                            A massive Content Delivery Network (CDN) streaming on Cyperhost servers or network.
                                        </li>
                                        <li className='text-gray-300 mb-1.5'>
                                            Threatening to DDoS and/or DDoSing or Doxing.
                                        </li>
                                        <li className='text-gray-300'>
                                            Stealing files that do not belong to you, especially stealing copyrighted files.
                                        </li>
                                    </ol>
                                    <p className='text-gray-300'>If you are doing any of these on your service, your service will be terminated without refund with the possibility of proper law enforcement agencies being notified if needed.</p>
                                </div>
                            </Element>
                            <Element name="refunds">
                                <div className='refunds mb-12'>
                                    <h1 className='text-gray-300 uppercase font-semibold text-lg mb-2.5'>Refunds and Disputes</h1>
                                    <p className='text-gray-300'>Servers are eligible for full refunds within Forty-Eight (48) hours of the creation of the server in our database. Refunds cannot be requested without probable cause. All completed server upgrade orders are final and not refundable. In agreeing to this Terms of Service you affirm that your order begins within a 48-hour “cooling-off” period, and we reserve the right to charge for the time utilized on your service prior to your cancellation. Any service purchased with a promotional code is not eligible for a refund. We may require additional information in order to process any refunds. After receiving a refund request, we will reach out via e-mail to confirm. If a confirmation is not received, we are not responsible for any subsequent charges or late issuance of the refund. We are not able to offer a refund if an imidiate cancellation request is submitted before getting a refund confirmation. Should a payment be taken back, via a chargeback or similar, Cyperhost reserves the right to terminate all accounts and services without warning.</p>
                                </div>
                            </Element>
                            <Element name="termination">
                                <div className='termination mb-12'>
                                    <h1 className='text-gray-300 uppercase font-semibold text-lg mb-2.5'>Termination of Use</h1>
                                    <p className='text-gray-300'>You agree that we may, at our sole discretion, suspend or terminate your access to all or part of our website and products with or without notice and for any reason, including, without limitation, breach of this User Agreement. Any suspected illegal, fraudulent or abusive activity may be grounds for terminating your relationship and may be referred to appropriate law enforcement authorities. Upon suspension or termination, your right to use the Resources we provide will immediately cease, and we reserve the right to remove or delete any information that you may have on file with us, including any account or login information. If your server is suspended for illegal activity or not paid for by the end user, Cyperhost has the right to the files on that related service.</p>
                                </div>
                            </Element>
                            <Element name="payment">
                                <div className='transfers mb-12'>
                                    <h1 className='text-gray-300 uppercase font-semibold text-lg mb-2.5'>Payment Information</h1>
                                    <p className='text-gray-300'>Upon creating an account with Cyperhost you agree to make all appropriate payments for the services received, in advance of the time period during duration in which such services are provided. Moreover, unless you notify Cyperhost that you would like to cancel all or any of the services rendered, all services will be billed on an ongoing basis. In addition, as a client of Cyperhost you take full responsibility to make sure that your payment information is always up to date and that all invoices are paid on time. If any invoice is placed into an overdue status it will result in suspension until the account balance has been paid in full. Cyperhost reserves full rights of changing product prices whenever, to whatever price they want, without warning.</p>
                                </div>
                            </Element>
                            <Element name="liability">
                                <div className='transfers mb-12'>
                                    <h1 className='text-gray-300 uppercase font-semibold text-lg mb-2.5'>Liability  Limitation and Exclusion</h1>
                                    <p className='text-gray-300 mb-5'>Cyperhost, under no circumstances, shall be held liable for any data loss, disruption of information, or distribution of information including but not limited to that of unauthorized access to our server systems or any other loss of data. Cyperhost shall not be held liable for any disruption, delay, or disconnection of services for any period of time.</p>
                                    <ol className='list-disc ml-5'>
                                        <li className='text-gray-300 mb-1.5'>
                                            Cyperhost is not responsible for any actions taken place in or on our game servers themselves. The sole role of Cyperhost is to provide server connectivity.
                                        </li>
                                        <li className='text-gray-300 mb-1.5'>
                                            Cyperhost is not responsible for any damage, alterations or actions taken by an additional user account. Additional user accounts are added at the sole discretion of the client.
                                        </li>
                                        <li className='text-gray-300 mb-1.5'>
                                            Cyperhost is not responsible for notifications of invoices due. It's the sole responsibility of the client to ensure that they are receiving emails from Cyperhost and that they are up to date on payments to Cyperhost.
                                        </li>
                                        <li className='text-gray-300 mb-1.5'>
                                            Cyperhost is not responsible for the public release of any mod pack(s), plugin pack(s), mod pack setup(s), or plugin setup(s) that have been created. If a client chooses to publicize any pack or setup created by Cyperhost, it is then the client’s responsibility to contact each author for permission to distribute the mods and plugins included.
                                        </li>
                                        <li className='text-gray-300 mb-1.5'>
                                            Cyperhost is not liable for any changes made outside of the initial server configuration. We also reserve the right to refuse support for 3rd party software that is not included in the original server setup by Cyperhost.
                                        </li>
                                    </ol>
                                </div>
                            </Element>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </main>
    )
}

export default Terms